import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-access/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/bio-access/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getBioAccess (id) {
  return request({
    url: `/bio-access/${id}`,
    method: 'get'
  })
}

export function deleteBioAccess (id) {
  return request({
    url: `/bio-access/${id}`,
    method: 'delete'
  })
}
export function exportExcel (data) {
  return request({
    url: 'bio-access/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
